import React, { Fragment } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";
import { agencyTheme } from "common/src/theme/agency";
import { ResetCSS } from "common/src/assets/css/style";
import "@redq/reuse-modal/es/index.css";
import {
  GlobalStyle,
  AgencyWrapper,
} from "../../containers/Agency/agency.style";
import Navbar from "../../containers/Agency/Navbar";
import GamesSection from "../../containers/Games";
import Footer from "../../containers/Agency/Footer";
import { DrawerProvider } from "common/src/contexts/DrawerContext";
import SEO from "../../components/seo";

export default () => {
  return (
    <ThemeProvider theme={agencyTheme}>
      <Fragment>
        <SEO title="Games by ROMA" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        {/* End of agency head section */}
        {/* Start agency wrapper section */}
        <AgencyWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <GamesSection />
          <Footer />
        </AgencyWrapper>
        {/* End of agency wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};
